@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: stretch; /*Can be any text*/
  src: url("./assets/fonts/StretchPro.otf") format("truetype");
}

/* :root {
  --primary: #662ad1;{
  --btnBg: linear-gradient(98.63deg, #662ad1 0%, #9f77e9 100%);}
  --btnBgHover: linear-gradient(98.63deg, #5422aa 0%, #8350e2 100%);
  --background: #fff;
  --boxShadow: rgb(0 0 0 / 20%) 0px 8px 24px;
}

.primary {
  color: var(--primary);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

select {
  color: #333;
}

a:hover {
  color: var(--primary);
}

.container {
  max-width: 1280px;
  margin: auto;
}

body {
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.4rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.1rem;
  line-height: 1.2;
}

p {
  font-size: 1.2rem;
}

.btn {
  padding: 14px 32px;
  border: none;
  background-image: var(--btnBg);
  color: #fff;
  border-radius: 24px 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  background-image: var(--btnBgHover);
}

input {
  padding: 12px 32px;
  border: 1px solid var(--primary);
  background: transparent;
  border-radius: 24px 4px;
  font-size: 1rem;
  margin-right: 0.8rem;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.1rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  h5 {
    font-size: 1.1rem;
    line-height: 1.2;
  }

  p {
    font-size: 1rem;
  }

  .btn {
    width: 100%;
    margin: 1rem 0;
  }

  input {
    width: 100%;
  }
}
 */
